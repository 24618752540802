import {
  ObjectSetInput, SharedConstants, SpreadSheetGetRowsOutput, SpreadSheetGetTabsOutput,
} from '@knitiv/api-client-javascript'
import { getterTree, mutationTree, actionTree } from 'typed-vuex'
import Vue from 'vue'
import { z } from 'zod'
import Dagre, { GraphLabel } from 'dagre'
import { ValueOf } from 'type-fest'
import {
  AvailableLinks,
  AvailableRepres,
} from '@/models/importfile'
import {
  ImportTemplate,
  Mutator,
  SavedImportTemplate,
  ImportFileObjectComponentLinkValidator,
  SavedImportTemplateValidator,
} from '@/models/imports'
import { APISIngleton } from '@/utils/api'
import { accessor } from '.'

export type TemplateErrors = Record<'filters' | 'mutators' | 'objects', string[]>

export interface SuggestionHeader {
  value: string;
  type: 'column' | 'mutator' | 'filter';
}

interface ImportFileState {
  infos: ImportTemplate['infos'];
  nodes: ImportTemplate['graph']['nodes'];
  edges: ImportTemplate['graph']['edges'];
  positions: ImportTemplate['graph']['positions'];

  headers: string[],
  repreHeaders: string[],
  excel: SpreadSheetGetRowsOutput;
  sheets: SpreadSheetGetTabsOutput['sheets'];

  name: ImportTemplate['name']
  version: ImportTemplate['version']
  filters: ImportTemplate['filters']
  mutators: ImportTemplate['mutators']

  // ---

  selectedNodeId: string,

  tab: number;

  target: string,

  canvasSize: number
}

export interface RepresentationCommitModel {
  lang: AvailableRepres
  column: SuggestionHeader;
  searchInput: string | null;
}

export const namespaced = true

const getDefaultState = (): ImportFileState => ({
  // Script
  infos: {
    worksheetId: 0,
    headersLine: 1,
    startLine: 2,
  },

  name: '',
  version: '1',
  mutators: [],
  filters: {},

  nodes: [],
  edges: [],
  positions: {},

  headers: [],
  repreHeaders: [],
  excel: {
    node_kid: '',
    rows: [],
    sheet_id: 0,
  },
  sheets: [],

  selectedNodeId: '',

  tab: 0,

  target: '',
  canvasSize: 100_000,
})

export const state = getDefaultState

export type RootState = ReturnType<typeof state>

export const getters = getterTree(state, {
  itemIcons() {
    const assoc: Record<SuggestionHeader['type'], string> = {
      column: 'microsoft-excel',
      mutator: 'transfer',
      filter: 'filter',
    }

    return assoc
  },
  itemType() {
    const assoc: Record<SuggestionHeader['type'], string> = {
      column: 'Colonne',
      mutator: 'Mutateur',
      filter: 'Filtre',
    }

    return assoc
  },
  errorNames() {
    const assoc: Record<keyof TemplateErrors, string> = {
      filters: 'Filtres',
      mutators: 'Mutateur',
      objects: 'Objets',
    }

    return assoc
  },
  getPosition: (state) => (nodeId: string) => {
    const { positions } = state

    return positions[nodeId]
  },
  headerNamesSuggestions(state) {
    const toHeader = (header: string, type: SuggestionHeader['type']): SuggestionHeader => ({
      value: header,
      type,
    })
    const excelHeaders = []
    if (state.excel.rows[state.infos.headersLine]) {
      excelHeaders.push(...Object.values(state.excel.rows[state.infos.headersLine]).map((x) => x[0]))
    }
    const modifiers = state.mutators

    const filters = Object.values(state.filters ?? {})

    const result: SuggestionHeader[] = []

    if (excelHeaders.length > 0) {
      result.push(...excelHeaders.map((header) => toHeader(header, 'column')))
    }

    if (modifiers && modifiers.length > 0) {
      result.push(...modifiers.map((m) => m.output).map((header) => toHeader(header, 'mutator')))
    }

    if (filters.length > 0) {
      result.push(...filters.map((header) => toHeader(header, 'filter')))
    }

    return result
  },
})

// @ts-ignore
const set = <T>(object: T, key: keyof T, value: unknown) => Vue.set(object, key, value)

export const mutations = mutationTree(state, {
  SET_MUTATORS(state, mutator: ImportTemplate['mutators']) {
    set(state, 'mutators', mutator)
  },
  SET_FILTERS(state, filters: ImportTemplate['filters']) {
    set(state, 'filters', filters)
  },
  SET_VERSION(state, version) {
    state.version = version
  },
  SET_INFO(state, { name, value }: { name: keyof ImportTemplate['infos'], value: ValueOf<ImportTemplate['infos']> }) {
    set(state.infos, name, value)
  },
  SET_INFOS(state, payload: ImportTemplate['infos']) {
    set(state, 'infos', payload)
  },
  SET_NAME(state, payload: string) {
    state.name = payload
  },
  SET_GRAPH(state, payload: ImportTemplate['graph']) {
    set(state, 'nodes', payload.nodes)
    set(state, 'edges', payload.edges)
    set(state, 'positions', payload.positions)
  },

  SET_HEADERS(state, headers) {
    set(state, 'headers', headers)
  },
  SET_EXCEL(state, payload: SpreadSheetGetRowsOutput) {
    set(state, 'excel', payload)
  },
  SET_SHEETS(state, payload: SpreadSheetGetTabsOutput['sheets']) {
    set(state, 'sheets', payload)
  },

  SET_TAB(state, tab: number) {
    state.tab = tab
  },

  ADD_NODE(state, node: ImportTemplate['graph']['nodes'][number]) {
    state.nodes.push(node)
  },
  REMOVE_NODE(state, nodeId: string) {
    const index = state.nodes.findIndex((node) => node.id === nodeId)
    state.nodes.splice(index, 1)
  },

  REMOVE_EDGES_TO(state, nodeId: string) {
    console.log('REMOVE_EDGES_TO', nodeId)
    const edgesToRemove = state.edges.filter((edge) => edge.target === nodeId || edge.source === nodeId)

    edgesToRemove.forEach((edge) => {
      console.log(`removing orphan edge between ${edge.source} and ${edge.target}`)
      const index = state.edges.findIndex((_edge) => _edge.id === edge.id)
      state.edges.splice(index, 1)
    })
  },

  SET_SELECTED_NODE_ID(state, nodeId: string) {
    state.selectedNodeId = nodeId
  },

  ADD_EDGE(state, edge: ImportTemplate['graph']['edges'][number]) {
    state.edges.push(edge)
  },

  ADD_REPRESENTATION_TO_SELECTED_NODE(state, payload: RepresentationCommitModel) {
    const index = state.nodes.findIndex((node) => node.id === state.selectedNodeId)
    state.nodes[index].representations.push({
      name: payload.column.value,
      subtype: payload.lang,
    })
  },
  REMOVE_REPRESENTATION_FROM_SELECTED_NODE(state, index: number) {
    const nodeIndex = state.nodes.findIndex((node) => node.id === state.selectedNodeId)

    state.nodes[nodeIndex].representations.splice(index, 1)
  },

  ADD_DATA_MUTATOR(state, mutator: Mutator) {
    state.mutators.push(mutator)
  },

  SET_SELECTED_NODE_READONLY(state, readOnly: boolean) {
    const nodeIndex = state.nodes.findIndex((node) => node.id === state.selectedNodeId)
    set(state.nodes[nodeIndex], 'action', readOnly ? 'read' : 'create/update')
  },
  SET_SELECTED_NODE_MASTER(state, master: ImportTemplate['graph']['nodes'][number]['master']) {
    const nodeIndex = state.nodes.findIndex((node) => node.id === state.selectedNodeId)
    set(state.nodes[nodeIndex], 'master', master)
  },
  SET_SELECTED_NODE_EXIT_IF_FOUND(state, exitIfFound: ImportTemplate['graph']['nodes'][number]['exitIfFound']) {
    const nodeIndex = state.nodes.findIndex((node) => node.id === state.selectedNodeId)
    set(state.nodes[nodeIndex], 'exitIfFound', exitIfFound)
  },
  SET_SELECTED_NODE_REPRECANBEEMPTY(state, repreCanBeEmpty: ImportTemplate['graph']['nodes'][number]['repreCanBeEmpty']) {
    const nodeIndex = state.nodes.findIndex((node) => node.id === state.selectedNodeId)
    set(state.nodes[nodeIndex], 'repreCanBeEmpty', repreCanBeEmpty)
  },
  SET_SELECTED_NODE_LINKER(state, linker: ImportTemplate['graph']['nodes'][number]['linker']) {
    const nodeIndex = state.nodes.findIndex((node) => node.id === state.selectedNodeId)
    set(state.nodes[nodeIndex], 'linker', linker)
  },

  SET_MODIFIER_OUTPUT(state, { id, value } : { id: string; value: string }) {
    const modifierIndex = state.mutators?.findIndex((mod) => mod.id === id)
    if (modifierIndex !== undefined && modifierIndex >= 0 && state.mutators) {
      const modifier = state.mutators?.[modifierIndex]
      modifier.output = value
      set(state.mutators, modifierIndex, modifier)
    }
  },

  SET_MODIFIER_MODIFIER(state, { id, value } : { id: string; value: Mutator['modifier'] }) {
    const modifierIndex = state.mutators?.findIndex((mod) => mod.id === id)
    if (modifierIndex !== undefined && modifierIndex >= 0 && state.mutators) {
      const modifier = state.mutators?.[modifierIndex]
      modifier.modifier = value
      set(state.mutators, modifierIndex, modifier)
    }
  },

  SET_MODIFIER_INPUTS(state, { id, value } : { id: string; value: Mutator['inputs'] }) {
    const modifierIndex = state.mutators?.findIndex((mod) => mod.id === id)
    if (modifierIndex !== undefined && modifierIndex >= 0 && state.mutators) {
      const modifier = state.mutators?.[modifierIndex]
      modifier.inputs = value
      set(state.mutators, modifierIndex, modifier)
    }
  },

  REPLACE_INPUT_AT(state, payload: { index: number; id: string; value: string }) {
    const modifierIndex = state.mutators?.findIndex((mod) => mod.id === payload.id)
    if (modifierIndex !== undefined && modifierIndex >= 0 && state.mutators) {
      const modifier = state.mutators?.[modifierIndex]
      modifier.inputs[payload.index] = payload.value
      set(state.mutators, modifierIndex, modifier)
    }
  },
  REPLACE_NODE_REPRE_AT(state, payload: { index: number; id: string; value: string }) {
    const nodeIndex = state.nodes?.findIndex((node) => node.id === payload.id)
    if (nodeIndex !== undefined && nodeIndex >= 0 && state.nodes) {
      const node = state.nodes?.[nodeIndex]
      node.representations[payload.index].name = payload.value
      set(state.nodes, nodeIndex, node)
    }
  },
  REMOVE_HEADER_FILTER(state, payload: string) {
    console.log(`Removing filter ${payload}`)
    if (state.filters?.[payload]) {
      Vue.delete(state.filters, payload)
    }
  },
  REMOVE_MUTATOR(state, mutatorId: string) {
    const index = state.mutators.findIndex((mutator) => mutator.id === mutatorId)
    state.mutators.splice(index, 1)
  },

  SET_NODE_POSITION(state, { id, position }: {id: string, position: [number, number]}) {
    set(state.positions, id, position)
  },

  SET_TARGET(state, target: string) {
    state.target = target
  },

  RESET(state) {
    Object.assign(state, getDefaultState())
  },
})

export const actions = actionTree({ state, getters, mutations }, {
/*   async setInfo ({ state, commit }, { name, value }) {
    if (name === 'headers_line') {
      const $api = APISIngleton.getInstance()
      const headersRow = await $api.SpreadSheetGetRows({
        node_kid: state.target,
        sheet_id:
      })
    }

    commit('SET_INFO', {
      name,
      value
    })
  }, */
  // eslint-disable-next-line require-await
  async generateTemplate({ state }): Promise<SavedImportTemplate> {
    const objects: SavedImportTemplate['objects'] = {}
    const positions: Record<string, [number, number]> = {}

    const $api = APISIngleton.getInstance()

    for (const node of state.nodes) {
      const component: SavedImportTemplate['objects'][number] = {
        class: node.class.kid,
        master: node.master,
        action: node.action,
        repre_can_be_empty: node.repreCanBeEmpty,

        componants: [],
      }

      node.representations.forEach((representation) => {
        component.componants.push({
          name: representation.name,
          subtype: representation.subtype,
          type: 'KREPRE',
        })
      })

      objects[node.id] = component

      const el = document.getElementById(node.id)
      const x = el ? (el.offsetLeft + (el.clientWidth / 2)) : 0
      const y = el ? (el.offsetTop + (el.clientHeight / 2)) : 0
      positions[node.id] = [x, y]
    }

    for (const edge of state.edges) {
      const { source, target } = edge

      const sourceNode = state.nodes.find((node) => node.id === source)
      const targetNode = state.nodes.find((node) => node.id === target)

      if (sourceNode && targetNode) {
        const component: z.infer<typeof ImportFileObjectComponentLinkValidator> = {
          key: target,
          type: 'KLINK',
          subtype: $api.constants.relations.COMPOSED_OF as AvailableLinks,
        }
        if (targetNode.linker) {
          component.linker = targetNode.linker.kid
        }
        objects[sourceNode.id].componants.push(component)
      } else {
        console.log('Can\'t find some nodes')
      }
    }

    const template: SavedImportTemplate = {
      data_modifiers: state.mutators,
      headers_line: state.infos.headersLine,
      header_filters: state.filters,
      name: state.name,
      start_line: state.infos.startLine,
      version: state.version,
      worksheet_id: state.infos.worksheetId,
      objects,
      graph: {
        nodes: state.nodes,
        edges: state.edges,
        positions,
      },
    }

    console.log('template', template)

    return template
  },

  async saveTemplate({ state }) {
    const $api = APISIngleton.getInstance()

    const template = await accessor.importfile.generateTemplate()

    console.log($api)
    console.log('template', template)
    console.log('state', state)

    const kid = state.target === '' ? 'K_NODE;_NR_001' : state.target

    if (!template.name) {
      throw new Error('Nom non défini')
    }

    const result = SavedImportTemplateValidator.safeParse(template)

    console.log('result', result)

    const object: ObjectSetInput = {
      items: [
        {
          target_objnum: kid,
          OBJECT_LIST: {
            [kid]: {
              isa: SharedConstants.nodes.DATAEXCH_TEMPLATE,
              REPRE_LIST: [
                {
                  lang: SharedConstants.representations.UNIVERSEL,
                  name: template.name,
                },
              ],
              kjson: JSON.stringify(template),
            },
          },
        },
      ],
    }
    console.log('object', object)

    return $api.objectSet(object)
  },

  loadTemplate({ commit }, template: SavedImportTemplate) {
    // Inverse of generateTemplate

    const {
      objects, graph, name, data_modifiers, header_filters, version, ...rest
    } = template

    const infos: ImportTemplate['infos'] = {
      headersLine: rest.headers_line,
      startLine: rest.start_line,
      worksheetId: rest.worksheet_id,
    }

    commit('SET_MUTATORS', data_modifiers)
    commit('SET_FILTERS', header_filters)
    commit('SET_VERSION', version)
    commit('SET_NAME', name)
    commit('SET_INFOS', infos)
    commit('SET_GRAPH', graph)
  },

  layoutNodes({ commit, state }): GraphLabel {
    console.log('objects', state.positions)

    const dg = new Dagre.graphlib.Graph()
    dg.setGraph({
      // rankdir: 'LR',
      // align: 'UR',
      nodesep: 100,
      ranksep: 100,
      edgesep: 100,
      marginx: 150,
      marginy: 100,
    })
    dg.setDefaultEdgeLabel(() => ({}))

    state.nodes.forEach((node) => {
      dg.setNode(node.id, { width: 200, height: 100 })
    })

    state.edges.forEach((edge) => {
      dg.setEdge(edge.source, edge.target)
    })

    Dagre.layout(dg)

    dg.nodes().forEach((nodeId) => {
      const node = dg.node(nodeId)

      if (!node) {
        return
      }

      const top = Math.round(node.y - node.height / 2)
      const left = Math.round(node.x - node.width / 2)

      commit('SET_NODE_POSITION', {
        id: nodeId,
        position: [left, top],
      })
    })

    return dg.graph()
  },

  fetchErrors({ state, getters }): TemplateErrors {
    const modifiers = state.mutators
    const errors: TemplateErrors = {
      filters: [],
      mutators: [],
      objects: [],
    }
    const headers: SuggestionHeader[] = getters.headerNamesSuggestions

    // filters
    const filtersNumber = Object.keys(state.filters ?? {})
    if (filtersNumber.length > 0) {
      errors.filters.push('Des filtres sont présents. Veuillez les remplacer.')
    }

    // mutators
    modifiers?.forEach((modifier) => {
      modifier.inputs.forEach((input) => {
        if (!headers.map((h) => h.value).includes(input)) {
          errors.mutators.push(`La colonne "${input}" du modificateur "${modifier.output}" est introuvable`)
        }
      })
    })

    // objects
    const { nodes } = state

    // check repres
    nodes.forEach((node) => {
      node.representations.forEach((repre) => {
        if (!headers.map((h) => h.value).includes(repre.name)) {
          const nodeRepre = accessor.representations.get(node.class.kid)
          errors.objects.push(`La colonne "${repre.name}" de l'objet "${nodeRepre}" est introuvable`)
        }
      })
    })

    let master = 0
    nodes.forEach((node) => {
      if (node.master) {
        master++
      }
    })

    if (master <= 0) {
      errors.objects.push('Aucun noeud maitre n\'a été trouvé !')
    } else if (master > 1) {
      errors.objects.push('Plusieurs noeuds maitres ont été trouvés')
    }

    return errors
  },
})
