

































import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class Alert extends Vue {
  get alert() {
    return this.$store.state.modal.alert
  }

  resolve(args: any) {
    this.$store.commit('modal/SET_ALERT_STATUS', false)
    this.alert.resolve(args)
  }

  get maxWidth() {
    return this.$vuetify.breakpoint.xsOnly ? '100%' : 500
  }
}
