import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VMask from 'v-mask'
import Toast from 'vue-toastification'

// CSS
import 'vue-toastification/dist/index.css'
import 'splitpanes/dist/splitpanes.css'
import 'jsoneditor/dist/jsoneditor.min.css'
import '@jsplumb/browser-ui/css/jsplumbtoolkit.css'
import 'flag-icon-css/css/flag-icon.min.css'
import '@/sass/main.scss'

import Dialog from '@/plugins/dialog'
import Notification from '@/plugins/notification'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = true

Vue.use(VMask)
Vue.use(Dialog)
Vue.use(Notification)

const options = {
  position: 'bottom-right',
  transition: 'Vue-Toastification__fade',
  timeout: 5000,
  closeOnClick: false,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: true,
  rtl: false,
  maxToasts: 3,
  newestOnTop: true,
}

Vue.use(Toast, options)


new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
