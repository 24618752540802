import { AutocompleteItem, ObjectInfoOutput } from '@knitiv/api-client-javascript'
import { nanoid } from 'nanoid'
import { BaseNode } from '@/models/node/base-node'
import { Representation } from '@/models/node/representation'
import { Right } from '@/models/node/right'
import { UndoStack } from '@/models/undoRedo/undoRedo'
import { APISIngleton, api } from '@/utils/api'

export class KNode extends BaseNode {
  id: string;

  constructor() {
    super()
    this.id = nanoid()
  }

  setId(id: string) {
    this.id = id

    return this
  }

  get kid() {
    return this.node.kid
  }

  get name() {
    return this.node.name
  }

  get icon() {
    return this.node.icon
  }

  static async fromKid(kid: string) {
    const output = await api().objectInfo({
      kid,
    })

    return KNode.fromObjectInfo(output)
  }

  static fromObjectInfo(input: ObjectInfoOutput) {
    const node = new KNode()

    node.node.kid = input.objid
    node.node.name = input.objname
    node.node.type = input.objtype

    Object.entries(input.repre.list).forEach(([key, representation]) => {
      const newRepre = Representation.fromAPI(representation)
      node.addRepresentation(key, newRepre)
    })

    Object.entries(input.droit.list).forEach(([key, droit]) => {
      const newRight = Right.fromAPI(droit)
      node.addDroit(key, newRight)
    })

    node.id = nanoid()

    return node
  }

  toAutocompleteItem() {
    const item: AutocompleteItem = {
      children: 0,
      file: 0,
      icon: '',
      isa: this.node.type,
      isa_lang: {
        isa: '',
        kid: '',
      },
      isa_name: '',
      isa_name_type_kid: '',
      kid: this.node.kid,
      kjson: '',
      lang: {
        isa: '',
        kid: '',
      },
      name: this.node.name,
      name_type_kid: '',
      repre_all: {},
      selectable: 1,
      type_kid: '',
    }
    return item
  }
}

export type KNodeActionNames =
  'SET_KID' |
  'SET_ID' |
  'LOAD_FROM_KID'

export class KNodeStack extends UndoStack<KNode, KNodeActionNames, 'data'> {
  constructor() {
    super({
      state: new KNode(),
      mutations: {},
      serializers: {},
    },
    {
      LOAD_FROM_KID: 'data',
      SET_KID: 'data',
      SET_ID: 'data',
    })
  }

  static async fromKid(kid: string) {
    const node = new KNodeStack()

    await node.loadFromKid(kid)

    return node
  }

  static fromKNode(node: KNode) {
    const knode = new KNodeStack()
    knode.setState(node)
    return knode
  }

  loadFromKid(kid: string) {
    return this.addMutation('LOAD_FROM_KID', (state, kid: string) => this.createMutation<KNode>({
      async execute() {
        const api = APISIngleton.getInstance()
        const infos = await api.objectInfo({ kid })
        const newNode = KNode.fromObjectInfo(infos)

        return { state: newNode, metadata: state }
      },
      rollback(originalNode) {
        if (originalNode) {
          return originalNode
        }
        return state
      },
    }))(kid)
  }

  setId(id: string) {
    return this.addMutation('SET_ID', (state, payloadAsId: string) => {
      const setKidMutation = this.createMutation<string>({
        execute() {
          const originalValue = state.id

          state.setId(payloadAsId)

          return { state, metadata: originalValue }
        },
        rollback(payloadAsId) {
          if (payloadAsId) {
            state.setId(payloadAsId)
          }
          return state
        },
      })
      return setKidMutation
    })(id)
  }

  setKid(kid: string) {
    return this.addMutation('SET_KID', (state, payloadAsKid: string) => {
      const setKidMutation = this.createMutation<string>({
        execute() {
          const originalValue = state.node.kid

          state.setKid(payloadAsKid)

          return { state, metadata: originalValue }
        },
        rollback(payloadAsKid) {
          if (payloadAsKid) {
            state.setKid(payloadAsKid)
          }
          return state
        },
      })
      return setKidMutation
    })(kid)
  }
}
