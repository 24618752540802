export default {
  apiVersion: '2',

  apiCache: {
    MF_Autocomplete2: 300,
    MF_Children2: 300,
    MF_Suggestion2: 300,
    Extract: 300,
    roleprofil: 300,
  },

  requestTimeout: 10_000,

  packetSize: 5_242_880,

  DROIT: {
    NONE: 0x00,
    ALL: 0x0F,
    READ: 0x08,
    CREATE: 0x04,
    MODIFY: 0x02,
    DELETE: 0x01,
    READ_CREATE: 0x0C,
    READ_CREATE_MODIFY: 0x0E,
    READ_CREATE_MODIFY_DELETE: 0x0F,
  },

  N_CARDINALITY: 999_999_999,

  query: {
    selectOperations: ['detail', 'count', 'sum', 'min', 'avg', 'max'],
    selectTypeNodes: ['REPRE', 'KID', 'ISA', 'KDATED', 'KDATEF', 'FILE', 'MIMETYPE'],
  },

  schema: {
    DEFAULT_CARD_FROM: 0,
    DEFAULT_CARD_TO: 1,
    DEFAULT_RELATION: 'K_NODE;DEFINED_BY',
  },

  viewerEnginesMimetypes: {
    image: /image\/.+/,
    json: /application\/json/,
    pdf: /application\/pdf/,
    text: /text\/.+/,
    video: /video\/.+/,
  },
}

export const N_MAX_CARDINALITY = 999_999_999
// export const PACKET_SIZE = 5242880 // 5 Mio to Octets
export const PACKET_SIZE = 1_000_000 // 5 Mio to Octets

// The interval at which to refresh home blocks
export const DEFAULT_CHECK_INTERVAL = 60_000
