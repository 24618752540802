import { getterTree, mutationTree, actionTree } from 'typed-vuex'
import Vue from 'vue'
import { make } from 'vuex-pathify'
import { nanoid } from 'nanoid'
import { cloneDeep } from 'lodash'
import {
  AddComponentPayload,
  AddItemPayload,
  RemoveComponentPayload,
  SelectedItem,
  SetComponentsPayload,
  SetHoveredItemPayload,
  SetItemPropertyPayload,
  SetItemKeyPayload,
  SwapItemsPayload,
  ScriptEvents,
  ScriptVariables,
  VariablePayload,
} from '@/models/pages/pagebuilder'
import { component } from '@/models/architecture'
import { PageBuilderComponent } from '@/components/definitions/pageBuilderComponent'
import { generateNewId } from '@/utils/pagebuilder'

export interface PageBuilderState {
  selectedItem: SelectedItem;
  items: Record<string, component<PageBuilderComponent>>;
  isPageBuilder: boolean;
  rootId: string;
  hoveredItems: SetHoveredItemPayload[];
  maxLevel: number;
  isPanning: boolean;
  actions: ScriptEvents;
  variables: ScriptVariables;
}

export const namespaced = true

export const initialState = (): PageBuilderState => ({
  selectedItem: undefined,
  items: {},
  isPageBuilder: false,
  rootId: '',
  hoveredItems: [],
  maxLevel: 0,
  actions: {},
  variables: {},

  isPanning: false,
})

export const state = initialState

export type RootState = ReturnType<typeof state>

export const getters = getterTree(state, {
  items(state): Record<string, component<PageBuilderComponent>> {
    return state.items
  },
  hoveredItem(state) {
    return state.hoveredItems[state.hoveredItems.length - 1]
  },
})

export const mutations = mutationTree(state, {
  ...make.mutations(state), // TODO remove
  CLEAR(state: PageBuilderState) {
    const s = initialState()
    Object.keys(s).forEach((key) => {
      // @ts-ignore
      state[key] = s[key]
    })
  },
  SET_IS_PAGE_BUILDER(state: PageBuilderState, status: boolean) {
    state.isPageBuilder = status
  },
  SET_ROOT_ID(state: PageBuilderState, id: string) {
    state.rootId = id
  },
  SET_ACTIONS(state: PageBuilderState, actions: ScriptEvents) {
    Vue.set(state, 'actions', actions)
  },
  SET_VARIABLES(state: PageBuilderState, variables: ScriptVariables) {
    Vue.set(state, 'variables', variables)
  },
  SET_ITEMS_RAW(state, payload: Record<string, component<PageBuilderComponent>>) {
    Vue.set(state, 'items', payload)
  },
  MERGE_ITEMS_RAW(state, payload: Record<string, component<PageBuilderComponent>>) {
    Vue.set(state, 'items', { ...state.items, ...payload })
  },
  REMOVE_ACTION(state, id: string) {
    Vue.delete(state.actions, id)
  },
  INSERT_ACTION(state, payload: any) {
    Vue.set(state.actions, nanoid(), payload)
  },
  REMOVE_VARIABLE(state, id: string) {
    Vue.delete(state.actions, id)
  },
  INSERT_VARIABLE(state, payload: VariablePayload) {
    console.log('payload', payload)
    console.log('state.variables', state.variables)
    if (!state.variables) {
      Vue.set(state, 'variables', {})
    }
    if (!state.variables[payload.target]) {
      console.log('set id', payload.target)
      Vue.set(state.variables, payload.target, {})
    }
    console.log('state.variables[payload.target]', state.variables[payload.target])
    if (!state.variables[payload.target][payload.type]) {
      console.log('set type', payload.type)
      Vue.set(state.variables[payload.target], payload.type, [])
    }
    console.log('set key', payload.key)
    state.variables[payload.target][payload.type].push(payload.key)
  },
  SET_ACTION_PROP(state, payload: SetItemPropertyPayload) {
    Vue.set(state.actions[payload.id], payload.key, payload.value)
  },
  INSERT_ITEM(state, event: AddItemPayload) {
    const { parent, index, component } = event

    if (component.id === parent) {
      console.error('Impossible to move an element to itself')
    } else if (!parent) {
      Vue.set(state.items, component.id, component)
    } else {
      const components = [...state.items[parent].components]
      const position = index ?? components.length

      components.splice(position, 0, component.id)

      // Set the list of components of the parent
      Vue.set(state.items[parent], 'components', components)
      // state.items[parent].components = components

      // Set the element itself
      Vue.set(state.items, component.id, component)
      // state.items[component.id] = component
    }
  },
  SET_COMPONENTS(state, payload: SetComponentsPayload) {
    const { components, id } = payload

    Vue.set(state.items[id], 'components', components)
  },
  ADD_COMPONENT(state, payload: AddComponentPayload) {
    const { index, parent, id } = payload

    const { components } = state.items[parent]
    const position = index ?? components.length

    components.splice(position, 0, id)

    Vue.set(state.items[parent], position, components)
  },
  REMOVE_ITEM(state, id: string) {
    Vue.delete(state.items, id)
  },
  REMOVE_COMPONENT(state, payload: RemoveComponentPayload) {
    const index = state
      .items[payload.from]
      .components
      // eslint-disable-next-line unicorn/prefer-array-index-of
      .findIndex((componentId: string) => componentId === payload.id)
    Vue.delete(state.items[payload.from].components, index)
  },
  SET_SELECTED_ITEM(state: PageBuilderState, id: string | undefined) {
    if (id) {
      const element = state.items[id]
      Vue.set(state, 'selectedItem', element)
    } else {
      Vue.set(state, 'selectedItem', undefined)
    }
  },
  SET_IS_PANNING(state: PageBuilderState, status: boolean) {
    state.isPanning = status
  },
  SET_ITEM_PROPERTY(state: PageBuilderState, payload: SetItemPropertyPayload) {
    // @ts-ignore
    Vue.set(state.items[payload.id].node.props, payload.key, cloneDeep(payload.value))
  },
  SET_DATA(state: PageBuilderState, payload: SetItemKeyPayload) {
    Vue.set(state.items[payload.id], 'data', cloneDeep(payload.value))
  },
  SET_NAME(state: PageBuilderState, payload: SetItemKeyPayload) {
    console.log('state', state)
    console.log('payload', payload)
    Vue.set(state.items[payload.id], 'name', payload.value)
  },
  SET_ITEM_STYLE(state: PageBuilderState, payload: SetItemKeyPayload) {
    Vue.set(state.items[payload.id].node, 'style', cloneDeep(payload.value))
  },
  ADD_HOVERED_ITEM(state: PageBuilderState, payload: SetHoveredItemPayload) {
    // if (state.hoveredItem) {
    //   state.hoveredItem.style.display = 'none'
    // }
    // if (ref) {
    //   state.maxLevel = state.maxLevel + 1
    //   state.hoveredItem = ref
    //   state.hoveredItem.style.display = 'flex'
    // } else {
    //   state.maxLevel = state.maxLevel - 1
    // }
    state.hoveredItems.push(payload)
    state.maxLevel++
  },
  REMOVE_HOVERED_ITEM(state: PageBuilderState, id: string) {
    state.hoveredItems = [...state.hoveredItems.filter((value) => value.id !== id)]
    state.maxLevel--
  },
  RESIZE_COMPONENTS(state, { id, size }: { id: string; size: number }) {
    const { components } = state.items[id]
    const newComponents = components.slice(0, size)
    Vue.set(state.items[id], 'components', newComponents)
  },
})

export const actions = actionTree({ state, getters, mutations }, {
  addItem({ commit }, event: AddItemPayload) {
    commit('INSERT_ITEM', event)
    if (event.select) {
      commit('SET_SELECTED_ITEM', event.component.id)
    }
  },
  addComponent({ commit }, payload: AddComponentPayload) {
    commit('ADD_COMPONENT', payload)
  },
  duplicateComponent({ dispatch, state }, id: string) {
    const generateTreeFromItem = (
      parentId: string,
      root = false,
    ): {
      tree: component<PageBuilderComponent>[];
      item: component<any>;
      root: string | undefined;
    } => {
      const components: component<PageBuilderComponent>[] = []
      if (!parentId) {
        // @ts-ignore
        return {}
      }
      const item: component<PageBuilderComponent> = cloneDeep(state.items[parentId])
      const newRootId = generateNewId(item.type)
      item.id = newRootId

      const childrenIds = item.components

      item.components = []
      childrenIds.forEach((childrenId) => {
        const { item: subitem, tree: subtree } = generateTreeFromItem(childrenId)
        item.components.push(subitem.id)
        components.push(...subtree)
      })

      // push the root
      components.push(item)

      return { tree: components, item, root: root ? newRootId : undefined }
    }

    const parents = Object
      .keys(state.items)
      // eslint-disable-next-line
      .reduce((acc: string[], itemId) => {
        if (state.items[itemId].components.includes(id)) {
          acc = [...acc, itemId]
        }
        return acc
      }, [])

    const { tree, root } = generateTreeFromItem(id, true)

    if (parents.length > 1) {
      throw new Error('Oops! A component cannot have mutiple parents')
    }

    const [parent] = parents

    tree.forEach((item) => {
      if (item.id === root) {
        const newPayload: AddComponentPayload = {
          id: item.id,
          parent,
        }
        dispatch('addComponent', newPayload)
      }

      const newPayload: AddItemPayload = {
        component: item,
        select: item.id === root,
      }
      dispatch('addItem', newPayload)
    })
  },
  async deleteItem({ commit, state, dispatch }, id: string) {
    // delete all child from component
    const { components } = state.items[id]
    const iterations = components.length - 1
    for (let i = 0; i <= iterations; i += 1) {
      // the id 0 always exists
      // if we delete id 0, 1, 2, i, they may not exist because deleting the previous one
      // would have changed the index and thus made the index obsolete
      await dispatch('deleteItem', components[0])
    }

    const elementsContainingId = Object
      .keys(state.items)
      // eslint-disable-next-line
      .reduce((acc: string[], itemId) => {
        if (state.items[itemId].components.includes(id)) {
          acc = [...acc, itemId]
        }
        return acc
      }, [])

    // delete parent references components
    elementsContainingId.forEach((refId) => {
      commit('REMOVE_COMPONENT', {
        from: refId,
        id,
      })
    })

    // delete component
    commit('REMOVE_ITEM', id)
  },
  removeComponent({ commit }, payload: RemoveComponentPayload) {
    commit('REMOVE_COMPONENT', payload)
  },
  setSelectedItem({ commit }, id: string | undefined) {
    commit('SET_SELECTED_ITEM', id)
  },
  setItemsRaw({ commit }, items: Record<string, component<PageBuilderComponent>>) {
    commit('SET_ITEMS_RAW', items)
  },
  setComponents({ commit }, payload: SetComponentsPayload) {
    commit('SET_COMPONENTS', payload)
  },
  mergeItemsRaw({ commit }, items: Record<string, component<PageBuilderComponent>>) {
    commit('MERGE_ITEMS_RAW', items)
  },
  setItemProperty({ commit }, payload: SetItemPropertyPayload) {
    console.log('payload', payload)
    commit('SET_ITEM_PROPERTY', payload)
  },
  setItemStyle({ commit }, payload: SetItemKeyPayload) {
    console.log('payload', payload)
    commit('SET_ITEM_STYLE', payload)
  },
  AddHoveredItem({ commit }, payload: SetHoveredItemPayload) {
    commit('ADD_HOVERED_ITEM', payload)
  },
  RemoveHoveredItem({ commit }, id: string) {
    commit('REMOVE_HOVERED_ITEM', id)
  },
  swapItems({ commit, state }, payload: SwapItemsPayload) {
    // get good values
    const from = state.items[payload.id].components[payload.event.from]

    // duplicate our array
    const components: string[] = [...state.items[payload.id].components]

    // reassign values
    components.splice(payload.event.from, 1)
    components.splice(payload.event.to, 0, from)

    // commit changes
    commit('SET_COMPONENTS', {
      id: payload.id,
      components,
    })
  },
  generate({ state }) {
    return state.items
  },
})
