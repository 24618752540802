import { Representation as APIRepresentation } from '@knitiv/api-client-javascript'
import { KElementBase } from './kelement-base'

export class Representation extends KElementBase {
  value: APIRepresentation['value'];

  lang: APIRepresentation['lang'];

  type_kid: APIRepresentation['type_kid'];

  constructor() {
    super()

    this.value = ''
    this.lang = {
      isa: '',
      kid: '',
      name: '',
    }
    this.type_kid = ''
  }

  static fromAPI(apiRepresentation: APIRepresentation) {
    const representation = new Representation()

    representation.value = apiRepresentation.value
    representation.lang = apiRepresentation.lang
    representation.type_kid = apiRepresentation.type_kid

    return representation
  }
}
