/* eslint-disable max-classes-per-file */
import {
  APIConstructor,
  WSAPIConstructor,
} from '@knitiv/api-client-javascript'
import Vue from 'vue'

export class APISIngleton {
  private static instance: APIConstructor;

  // eslint-disable-next-line no-useless-constructor,@typescript-eslint/no-empty-function
  private constructor() {}

  public static getInstance() {
    return this.instance
  }

  public static setInstance(instance: APIConstructor) {
    // instance.on('error', (error: APIErrorBase) => {
    //   console.error('API error', error)
    //   if (error.errno === ERROR.NO_TOKEN || error.errno === ERROR.RESTRICTED_AREA) {
    //     console.log('Ouch')
    //   }
    // })

    this.instance = instance
  }
}

export class WsManagerSingleton {
  private static instance: WSAPIConstructor;

  public static getInstance() {
    if (!WsManagerSingleton.instance) {
      WsManagerSingleton.instance = new WSAPIConstructor()
    }
    return WsManagerSingleton.instance
  }

  private constructor() {
    throw new Error('You can\'t initialize a singleton this way')
  }
}

export const _wsSingleton = WsManagerSingleton.getInstance()

export const ws = (): Promise<WSAPIConstructor> => new Promise((resolve) => {
  const instance = WsManagerSingleton.getInstance()
  return instance.isReady() ? resolve(instance) : resolve(instance.init())
})

export const api = () => APISIngleton.getInstance()

/** @deprecated */
export const InjectAPI = Vue.extend({
  data: () => ({
    $api: null,
  }),
  created() {
    // @ts-ignore
    this.$api = APISIngleton.getInstance()
  },
})
