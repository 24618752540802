















































import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class Prompt extends Vue {
  value = ''

  get prompt() {
    return this.$store.state.modal.prompt
  }

  resolve() {
    this.$store.commit('modal/SET_PROMPT_STATUS', false)
    this.prompt.resolve(this.value)
    this.value = ''
  }

  reject() {
    this.$store.commit('modal/SET_PROMPT_STATUS', false)
    this.prompt.resolve('cancelled')
    this.value = ''
  }

  get maxWidth() {
    return this.$vuetify.breakpoint.xsOnly ? '100%' : 500
  }

  mounted() {
    if (this.prompt.props.value) {
      this.value = this.prompt.props.value
    }
  }
}
