export class KElementBase {
  kid: string;

  kdated?: string;

  kdatef?: string;

  kdatecreation?: string;

  ref_propri?: string;

  constructor() {
    this.kid = ''
    this.kdated = ''
    this.kdatef = ''
    this.kdatecreation = ''
    this.ref_propri = ''
  }
}
