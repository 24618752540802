import Vue, { VueConstructor } from 'vue'
import { accessor } from '@/store'
import * as CSS from 'csstype'

declare module 'vue/types/vue' {
  interface Vue {
    $dialog: Dialog;
  }
}
interface Button {
  props?: any;
  label: string;
}
type Buttons = Record<string, Button>

interface OpenProps {
  dialog?: any;
  maxWidth?: CSS.Property.MaxWidth | number;
  component?: any,
  scrollable?: boolean;
}

const defaultProps: OpenProps = {
  maxWidth: 500,
  scrollable: false
}

// <U extends VueConstructor<Vue>
function open<T>(component: VueConstructor<Vue>, props: OpenProps = defaultProps): Promise<T | 'close'> {
  return new Promise((resolve, reject) => {
    accessor.modal.SET_MODAL_CONTENT(component)
    accessor.modal.SET_MODAL_PROPS(props)
    accessor.modal.SET_MODAL_STATUS(true)

    accessor.modal.SET_MODAL_RESOLVE(resolve)
    accessor.modal.SET_MODAL_REJECT(reject)
  })
}

class Dialog {
  // eslint-disable-next-line unicorn/no-object-as-default-parameter
  open = open

  alert(title: string, message: string, buttonLabel = 'OK', props: any = {}) {
    props.buttonLabel = buttonLabel
    props.title = title
    props.message = message
    return new Promise((resolve, reject) => {
      accessor.modal.SET_ALERT_PROPS(props)
      accessor.modal.SET_ALERT_STATUS(true)

      accessor.modal.SET_ALERT_RESOLVE(resolve)
      accessor.modal.SET_ALERT_REJECT(reject)
    })
  }

  confirm <T extends Buttons = Buttons>(title: string, message: string, buttons: T, props: any = {}): Promise<keyof T> {
    props.title = title
    props.message = message
    props.buttons = buttons
    return new Promise((resolve, reject) => {
      accessor.modal.SET_CONFIRM_PROPS(props)
      accessor.modal.SET_CONFIRM_STATUS(true)

      accessor.modal.SET_CONFIRM_RESOLVE(resolve)
      accessor.modal.SET_CONFIRM_REJECT(reject)
    })
  }

  prompt(
    title: string,
    message: string,
    acceptLabel = 'OK',
    cancelLabel = 'Cancel',
    props: any = {},
  ): Promise<string | 'cancelled'> {
    props.acceptLabel = acceptLabel
    props.cancelLabel = cancelLabel
    props.title = title
    props.message = message
    return new Promise((resolve, reject) => {
      accessor.modal.SET_PROMPT_PROPS(props)
      accessor.modal.SET_PROMPT_STATUS(true)

      accessor.modal.SET_PROMPT_RESOLVE(resolve)
      accessor.modal.SET_PROMPT_REJECT(reject)
    })
  }
}

export default {
  install: (Vue: VueConstructor) => {
    Vue.prototype.$dialog = new Dialog()
  }
}
