import { ObjectSetInput, SharedConstants } from '@knitiv/api-client-javascript'
import { getterTree, mutationTree, actionTree } from 'typed-vuex'
import { createMigrator, MigrationSchema } from '@knitiv/migration'
import migrations from '../models/class-designer/migrations'
import { api } from '@/utils/api'
import { ClassDesignerStack } from '@/pages/admin/models/class-designer'
import {
  ClassDesignerActionNames, ClassDesignerSaveState, ClassDesignerStoreState, StackCommit,
} from '@/models/class-designer/models'

const stack = new ClassDesignerStack()

export const namespaced = true

const getDefaultState = (): ClassDesignerStoreState => ({
  target: '',
  name: '',
  version: '2.0.0',
})

export const state = getDefaultState

export type RootState = ReturnType<typeof state>

export const getters = getterTree(state, {
  stack() {
    return stack
  },
})

export const mutations = mutationTree(state, {
  SET_NAME(state, name: ClassDesignerStoreState['name']) {
    state.name = name
  },
  SET_VERSION(state, version: ClassDesignerStoreState['version']) {
    state.version = version
  },
  SET_TARGET(state, target: ClassDesignerStoreState['target']) {
    state.target = target
  },

  RESET(state) {
    Object.assign(state, getDefaultState())
  },
})

export const actions = actionTree({ state, getters, mutations }, {
  async stackCommit({ getters }, payload: StackCommit<ClassDesignerActionNames>) {
    await getters.stack.commit(payload.name, payload.payload)
  },
  async load({ commit }, kid: string) {
    const node = await api().objectInfo({ kid })
    const schema = JSON.parse(node.kjson) as MigrationSchema
    console.log('schema.version', schema.version)

    const migrator = createMigrator<ClassDesignerSaveState>(migrations)
    const result = await migrator.migrate(schema)

    commit('SET_NAME', node.objname)
    commit('SET_VERSION', result.version)
    commit('SET_TARGET', kid)

    await stack.import(result.schema)
  },
  async save({ state, getters, commit }) {
    const kid = state.target === '' ? 'K_NODE;_NR_001' : state.target
    const stack = await getters.stack.export()

    if (!state.name) {
      throw new Error('Nom non défini')
    }

    const save: ClassDesignerSaveState = {
      schema: stack,
      version: state.version,
    }

    const object: ObjectSetInput = {
      items: [
        {
          target_objnum: kid,
          OBJECT_LIST: {
            [kid]: {
              isa: SharedConstants.nodes.CLASS_DESIGNER_SCHEMA,
              REPRE_LIST: [
                {
                  lang: SharedConstants.representations.UNIVERSEL,
                  name: state.name,
                },
              ],
              kjson: JSON.stringify(save),
            },
          },
        },
      ],
    }

    const result = await api().objectSet(object)

    console.log('result', result)

    const generated = Object.keys(result.result)[0]

    commit('SET_TARGET', generated)

    return generated
  },
})
