import { Right as APIRight } from '@knitiv/api-client-javascript'
import { KElementBase } from './kelement-base'

export class Right extends KElementBase {
  kright = 0;

  role_name = '';

  static fromAPI(apiRepresentation: APIRight) {
    const right = new Right()

    right.kright = apiRepresentation.kright
    right.role_name = apiRepresentation.role_name

    return right
  }
}
