








































import Vue, { PropType } from 'vue'
import copy from 'copy-to-clipboard'

export default Vue.extend({
  props: {
    resolve: {
      type: Function as PropType<(arg: any) => any>,
      required: true,
    },
    reject: {
      type: Function as PropType<(arg: any) => any>,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    error: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      description: '',
    }
  },
  async mounted() {
    this.description = `
  URL: ${this.url}

  Stack Trace:
  \`\`\`
  ${this.error}
  \`\`\`
`
  },
  methods: {
    copy() {
      copy(this.error.toString())
    },
    createReport() {
      window.open(
        encodeURI(
          `https://git.knitiv.net/knitiv/knitivvue5/issues/new?issue[title]=[Exception non gérée] - &issue[description]=${this.description}`
        )
      )
    }
  }
})
