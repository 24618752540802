




// Can't use API here, only on sub-components
import Vue from 'vue'
import uncaughtErrorDialogVue from './components/uncaughtErrorDialog.vue'

export default Vue.extend({
  name: 'App',

  errorCaptured(err: Error, _vm: Vue, info: string) {
    console.error(`Error captured from ${info}`, err)

    const url = window.location.href

    this.$toast.error('Une erreur est survenue. Cliquez ici pour obtenir plus de détails', {
      onClick: async (closeToast) => {
        await this.$dialog.open(uncaughtErrorDialogVue, {
          maxWidth: '80%',
          component: {
            url,
            error: err.toString()
          },
        })
        closeToast()
      },
    })

    return false
  },
})
