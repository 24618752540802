import { render, staticRenderFns } from "./page.vue?vue&type=template&id=097fbb1e&scoped=true&"
import script from "./page.vue?vue&type=script&lang=ts&"
export * from "./page.vue?vue&type=script&lang=ts&"
import style0 from "./page.vue?vue&type=style&index=0&id=097fbb1e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "097fbb1e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VApp,VBtn,VIcon,VMain,VTooltip})
