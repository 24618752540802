import { AutocompleteItem, KRelationV4 } from '@knitiv/api-client-javascript'
import { KNodeStack } from './knode-stack'
import { Position } from '@/models/pages/pagebuilder'
import { UndoStack } from '@/models/undoRedo/undoRedo'
import { ClassDesignerActionNames, ClassDesignerTypes } from '@/models/class-designer/models'

export interface SetNodePositionPayload {
  id: string;
  position: Position;
}

export interface AddNodePayload {
  node: KNodeStack;
  id: string,
}

export interface AddNodePayloadExported {
  node: string;
  id: string,
}

export interface Cardinality {
  from: number;
  to: number;
}
export interface AddLinkPayload {
  source: string;
  target: string;
  type: KRelationV4;
  id: string;
  cardinality: Cardinality;
  linker?: string;
}
export interface LinkPickerResult {
  type: KRelationV4;
  linker?: AutocompleteItem;
  cardinality: Cardinality;
  id: string;
}

export interface ClassDesignerState {
  nodes: KNodeStack[]
  links: AddLinkPayload[]
}

export class ClassDesignerStack extends UndoStack<ClassDesignerState, ClassDesignerActionNames, ClassDesignerTypes> {
  addNodeToCanvas: (payload: AddNodePayload) => Promise<void>

  removeNodeFromCanvas: (payload: AddNodePayload) => Promise<void>

  addLink: (payload: AddLinkPayload) => Promise<void>

  removeLink: (payload: AddLinkPayload) => Promise<void>

  editLink: (payload: AddLinkPayload) => Promise<void>

  setNodePosition: (payload: SetNodePositionPayload) => Promise<void>

  constructor() {
    super({
      state: {
        nodes: [],
        links: [],
      },
      mutations: {},
      serializers: {},
    },
    {
      ADD_LINK: 'data',
      ADD_NODE: 'data',
      EDIT_LINK: 'data',
      REMOVE_LINK: 'data',
      REMOVE_NODE: 'data',

      SET_NODE_POSITION: 'view',
    })

    this.addNodeToCanvas = this.addMutation<AddNodePayload>(
      'ADD_NODE',
      (state, { node, id }) => this.createMutation({
        execute: () => {
          state.nodes.push(node)

          return {
            state,
          }
        },
        rollback: () => {
          const index = state.nodes.findIndex((n) => n.getState().id === id)
          state.nodes.splice(index, 1)
          return state
        },
      }),
      {
        onSave(input: AddNodePayload): AddNodePayloadExported {
          const state = input.node.getState()
          return { node: state.kid, id: state.id }
        },
        async onRestore(_input): Promise<AddNodePayload> {
          const input = _input as AddNodePayloadExported
          const node = await KNodeStack.fromKid(input.node)
          node.setId(input.id)
          return { node, id: input.id }
        },
      },
    )

    this.removeNodeFromCanvas = this.addMutation<AddNodePayload>(
      'REMOVE_NODE',
      (state, { node, id }) => this.createMutation({
        execute: () => {
          const index = state.nodes.findIndex((x) => x.getState().id === id)
          state.nodes.splice(index, 1)
          return { state }
        },
        rollback: () => {
          state.nodes.push(node)

          return state
        },
      }),
    )

    this.addLink = this.addMutation<AddLinkPayload>(
      'ADD_LINK',
      (state, link) => this.createMutation({
        execute: () => {
          state.links.push(link)
          return { state }
        },
        rollback: () => {
          const index = state.links.findIndex((l) => l.id === link.id)
          state.nodes.splice(index, 1)
          return state
        },
      }),
    )

    this.editLink = this.addMutation<AddLinkPayload>(
      'EDIT_LINK',
      (state, newState) => this.createMutation({
        execute: () => {
          const index = state.links.findIndex((l) => l.id === newState.id)
          const oldValue = { ...state.links[index] }
          state.links[index] = newState
          return { state, metadata: oldValue }
        },
        rollback: (oldValue) => {
          if (oldValue) {
            const index = state.links.findIndex((l) => l.id === newState.id)
            state.links[index] = oldValue
          }
          return state
        },
      }),
    )

    this.removeLink = this.addMutation<AddLinkPayload>(
      'REMOVE_LINK',
      (state, link) => this.createMutation({
        execute: () => {
          const index = state.links.findIndex((l) => l.id === link.id)
          state.nodes.splice(index, 1)
          return { state }
        },
        rollback: () => {
          state.links.push(link)
          return state
        },
      }),
    )

    this.setNodePosition = this.addMutation<SetNodePositionPayload>(
      'SET_NODE_POSITION',
      (state) => this.createMutation({
        execute: () => ({ state }),
        rollback: () => state,

      }),
    )
  }
}
