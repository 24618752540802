import { VueConstructor } from 'vue/types/umd'

declare module 'vue/types/vue' {
  interface Vue {
    $notification: Notifier;
  }
}

class Notifier {
  show(title: string, options?: NotificationOptions | undefined): Promise<Notification> {
    return new Promise((resolve, reject) => {
      if (!('Notification' in window)) {
        console.error('Ce navigateur ne prends pas en charge les notifications')
      } else if (Notification.permission === 'granted') {
        return resolve(new Notification(title, options))
      } else if (Notification.permission !== 'denied') {
        Notification.requestPermission().then((permission) => {
          if (permission === 'granted') {
            return resolve(new Notification(title, options))
          }
        })
      } else {
        return reject(new Error(`Notification permission ${Notification.permission}`))
      }
    })
  }
}

export default {
  install: (Vue: VueConstructor) => {
    Vue.prototype.$notification = new Notifier()
  }
}
