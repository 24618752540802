import { z } from 'zod'

export enum BooleanNumbered {
  ZERO,
  ONE
}

export enum traceLevel {
  DEBUG, LOG, WARNING, ERROR, FATAL
}

export interface BaseEntry {
  actif: BooleanNumbered;
  trace: traceLevel;
}

// eslint-disable-next-line
export interface GlobalVariable {}

// eslint-disable-next-line
export interface GlobalTemplate {}

export interface Login {
  name: string | '_self_';
  pwd: string;
  host: string;
}

export interface AuthConnection {
  user: string;
  pwd: string;
  ip: string;
  port: string;
}

export interface ControlBase extends BaseEntry {
  index: string;
  name: string;
  isa: string;
  lang_kid: string;
}

export type Auth = Record<string, AuthConnection>

export enum ANSWER { YES = 'YES', NO = 'NO'}

export type ControlEmpty = {
  action: 'CONTROL_EMPTY';
} & ControlBase

export type ControlExist = {
  action: 'CONTROL_EXIST';
} & ControlBase

export type ControlUnique = {
  action: 'CONTROL_UNIQUE';
  type: 'BY_KID' | 'BY_REPRE';
} & ControlBase

export type Control = ControlEmpty | ControlExist | ControlUnique

export interface ScriptInfos extends BaseEntry {
  name: string;
  comment: string;
  data_file: string;
  do_ask?: ANSWER;
  do_next?: ANSWER;
  do_empty?: ANSWER;
  load_callback?: string;
  database_kid?: string | '_ASK_';
  action?: 'K';
  synchro_mode?: 'NORMAL';
}

export interface ScriptInfosFile extends ScriptInfos{
  type: 'FILE';
  data_file: string;
  worksheet_id?: string;
  worksheet_name?: string;
  header_line?: number;
  start_line?: number;
  stop_line?: number;
  stock_line?: number;
}

export interface ScriptInfosSQL extends ScriptInfos {
  type: 'SQL';
  sql_auth: string;
  header_line?: number;
  start_line?: number;
  stop_line?: number;
  stock_line?: number;
}

export interface ScriptInfosScenario extends ScriptInfos {
  type: 'SCENARIO';
  scenario_file: string;
}

export enum EmailType {
  TO ='TO',
  CC = 'CC',
  BCC = 'BCC',
  REPLYTO = 'REPLYTO',
  FILE_OUT = 'FILE_OUT',
  FILE_IN = 'FILE_IN'
}

export interface EmailAddress extends BaseEntry {
  type: EmailType;
  addr: string;
  name: string;
}

export interface ScriptEmail extends BaseEntry {
  addr: EmailAddress[];
}

export enum KOON_MODES {
  NORMAL = 'NORMAL',
  REPLACE_LIST_LINK = 'REPLACE_LIST_LINK',
  REPLACE_ALL_LINK = 'REPLACE_ALL_LINK',
  EXACT = 'EXACT'
}

export enum BlocType {
  // KLINK = 'KLINK',
  // KREPRE = 'KREPRE',
  // KNODE = 'KNODE',
  // CELL_CONTROL = 'CELL_CONTROL',
  // KTEMPLATE = 'KTEMPLATE',

  INFORMATIONS = 'informations',
  // TEMPLATES = 'templates',
  // TARGET = 'target',
  // EMAILS = 'emails',

  HEADER_FILTERS = 'header_filters',
  DATA_MUTATORS = 'data_mutators',
  OBJECTS = 'objects',
  ERRORS = 'errors',
}

export const MutatorKeysValidator = z.union([
  z.literal('str_pad_with_two_zero'),
  z.literal('remove_spaces'),
  z.literal('trim_one_left'),
  z.literal('concat_hyphen'),
  z.literal('format_date'),
  z.literal('remove_specials_characters'),
  z.literal('select_last'),
  z.literal('today'),
  z.literal('now'),
])

export type MutatorKeys = z.infer<typeof MutatorKeysValidator>

export type MutatorsFn = {
  value: MutatorKeys;
  name: string;
}[]

export const mutatorFunctions: MutatorsFn = [
  { value: 'str_pad_with_two_zero', name: 'Préfixe de 2 zéros' },
  { value: 'remove_spaces', name: 'Suppression des espaces' },
  { value: 'trim_one_left', name: 'Supprimer le 1er caractere' },
  { value: 'concat_hyphen', name: 'Concaténer avec un tiret' },
  { value: 'format_date', name: 'Formater la date' },
  { value: 'remove_specials_characters', name: 'Supprimer les caracteres spéciaux' },
  { value: 'select_last', name: 'Sélectionner la derniere colonne' },
  { value: 'today', name: 'Aujourd\'hui' },
  { value: 'now', name: 'Maintenant' },
]

export const MutatorValidator = z.object({
  id: z.string(),
  inputs: z.array(z.string()),
  modifier: MutatorKeysValidator,
  output: z.string(),
})

export type Mutator = z.infer<typeof MutatorValidator>
export type Mutators = Mutator[]
export type HeaderFilters = Record<string, string>

// ---

export const ImportTemplateGraphEdgeValidator = z.object({
  id: z.string(),
  source: z.string(),
  target: z.string(),
  subtype: z.string(),
})

export const ImportTemplateGraphNodeValidator = z.object({
  id: z.string(),
  class: z.object({
    kid: z.string(),
  }),
  master: z.boolean().optional(),
  action: z.union([z.literal('read'), z.literal('create/update')]),
  exitIfFound: z.boolean(),
  repreCanBeEmpty: z.boolean().optional(),
  linker: z.any().optional(),
  representations: z.array(z.object({
    name: z.string(),
    subtype: z.string(),
  })),
  type: z.string(),
})

export const ImportTemplateGraphValidator = z.object({
  edges: z.array(ImportTemplateGraphEdgeValidator),
  nodes: z.array(ImportTemplateGraphNodeValidator),
  positions: z.record(z.tuple([z.number(), z.number()])),
})

export const ImportFileObjectComponentLinkValidator = z.object({
  type: z.literal('KLINK'),
  subtype: z.string(),
  key: z.string(),
  linker: z.any().optional(),
})

export const ImportFileObjectComponentRepreValidator = z.object({
  type: z.literal('KREPRE'),
  subtype: z.string(),
  name: z.string(),
})

export const ImportTemplateObjectsValidator = z.record(z.object({
  class: z.string(),
  master: z.boolean().optional(),
  action: z.union([z.literal('read'), z.literal('create/update')]).optional(),
  repre_can_be_empty: z.boolean().optional(),
  componants: z.array(
    z.union([
      ImportFileObjectComponentRepreValidator,
      ImportFileObjectComponentLinkValidator,
    ]),
  ),
}))

export const ImportTemplateValidator = z.object({
  name: z.string(),
  version: z.string(),
  infos: z.object({
    worksheetId: z.number(),
    headersLine: z.number(),
    startLine: z.number(),
  }),
  mutators: z.array(MutatorValidator),
  filters: z.record(z.string()),
  graph: ImportTemplateGraphValidator,
  // objects: ImportTemplateObjectsValidator
})

export const BaseImportTemplateValidator = z.object({
  worksheet_id: z.number(),
  headers_line: z.number(),
  start_line: z.number(),
  header_filters: z.record(z.string()),
  objects: ImportTemplateObjectsValidator,
})

export const BackImportTemplateValidator = BaseImportTemplateValidator.merge(z.object({
  data_modifiers: z.array(MutatorValidator.omit({ id: true })),
}))

export const SavedImportTemplateValidator = BaseImportTemplateValidator.merge(z.object({
  name: z.string(),
  version: z.string(),
  graph: ImportTemplateGraphValidator,
  data_modifiers: z.array(MutatorValidator),
}))

export type BackImportTemplate = z.infer<typeof BackImportTemplateValidator>
export type ImportTemplate = z.infer<typeof ImportTemplateValidator>
export type SavedImportTemplate = z.infer<typeof SavedImportTemplateValidator>
export type ImportTemplateObjects = z.infer<typeof ImportTemplateObjectsValidator>
export type ImportTemplateGraphNode = z.infer<typeof ImportTemplateGraphNodeValidator>
export type ImportTemplateGraphEdge = z.infer<typeof ImportTemplateGraphEdgeValidator>
export type ImportFileObjectComponentLink = z.infer<typeof ImportFileObjectComponentLinkValidator>
export type ImportFileObjectComponentRepre = z.infer<typeof ImportFileObjectComponentRepreValidator>
