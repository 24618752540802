import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import Home from '@/pages/index.vue'

import { Auth } from '@/middleware/auth'

Vue.use(VueRouter)

const dummy = () => import(/* webpackChunkName: "dummy" */ '../pages/dummy.vue')

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Accueil'
    }
  },
  // Login ---------------------------------------------------------------------
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../pages/login/index.vue'),
    meta: {
      title: 'Connexion'
    }
  },
  {
    path: '/login/workspace',
    name: 'LoginWorkspace',
    component: () => import(/* webpackChunkName: "login-workspace" */ '../pages/login/workspace.vue'),
    meta: {
      title: 'Espace de travail'
    }
  },
  // Dummy ---------------------------------------------------------------------
  {
    path: '/account',
    name: 'Account',
    component: dummy,
    meta: {
      title: 'Mon compte'
    }
  },
  // Admin ---------------------------------------------------------------------
  {
    path: '/admin/oo/:id',
    name: 'AdminOO',
    component: () => import(/* webpackChunkName: "adminoo" */ '../pages/admin/oo/_id.vue'),
    meta: {
      title: 'Administration'
    }
  },
  {
    path: '/admin/upload',
    name: 'AdminUploads',
    component: dummy,
    meta: {
      title: 'Gestion des uploads'
    }
  },
  {
    path: '/admin/class-designer',
    name: 'AdminClassDesigner',
    component: () => import(/* webpackChunkName: "class-designer" */ '../pages/admin/class-designer/index.vue'),
    meta: {
      title: 'Créateur de classes'
    }
  },
  {
    path: '/admin/class-designer/new',
    name: 'AdminClassDesignerNew',
    component: () => import(/* webpackChunkName: "class-designer-new" */ '../pages/admin/class-designer/new.vue'),
    meta: {
      title: 'Créateur de classes'
    }
  },
  {
    path: '/admin/class-designer/edit/:id',
    name: 'AdminClassDesignerEdit',
    component: () => import(/* webpackChunkName: "class-designer-edit" */ '../pages/admin/class-designer/edit/_id.vue'),
    meta: {
      title: 'Créateur de classes'
    }
  },
  // Form builder ---------------------------------------------------------------------
  {
    path: '/form-builder',
    name: 'Formbuilder',
    component: dummy,
    meta: {
      title: 'Créateur de formulaires'
    }
  },
  // Import en masse ---------------------------------------------------------------------
  {
    path: '/importfile',
    component: () => import(/* webpackChunkName: "importfile" */ '../pages/importfile.vue'),
    meta: {
      title: 'Import de masse'
    },
    children: [
      {
        path: '',
        name: 'ImportFile',
        redirect: 'dashboard'
      },
      {
        path: 'dashboard',
        component: () => import(/* webpackChunkName: "importfile-dashboard" */ '../pages/importfile/dashboard.vue'),
        meta: {
          title: 'Import de masse'
        },
        children: [
          {
            name: 'ImportFileDashboard',
            path: '',
            redirect: 'datasources'
          },
          {
            path: 'datasources',
            name: 'ImportFileDashboardDatasource',
            component: () => import(/* webpackChunkName: "importfile-datasource" */ '../pages/importfile/dashboard/datasources.vue'),
            meta: {
              title: 'Import de masse'
            },
          },
          {
            path: 'templates',
            name: 'ImportFileDashboardTemplates',
            component: () => import(/* webpackChunkName: "importfile-templates" */ '../pages/importfile/dashboard/templates.vue'),
            meta: {
              title: 'Import de masse'
            },
          },
          {
            path: 'integrations',
            name: 'ImportFileDashboardIntegrations',
            component: () => import(/* webpackChunkName: "importfile-integrations" */ '../pages/importfile/dashboard/integrations.vue'),
            meta: {
              title: 'Import de masse'
            },
          }
        ]
      },
      {
        path: 'templates/new',
        name: 'ImportFileTemplatesNew',
        component: () => import(/* webpackChunkName: "new" */ '../pages/importfile/templates/new.vue'),
        meta: {
          title: 'Créateur de classes'
        }
      },
      {
        path: 'templates/edit/:id',
        name: 'ImportFileTemplatesEdit',
        component: () => import(/* webpackChunkName: "edit" */ '../pages/importfile/templates/edit/_id.vue'),
        meta: {
          title: 'Créateur de classes'
        }
      },

    ]
  },

  // Dev ---------------------------------------------------------------------
  {
    path: '/dev',
    name: 'Dev',
    component: () => import(/* webpackChunkName: "dev" */ '../pages/dev.vue'),
    meta: {
      title: 'Connexion'
    }
  },
  {
    path: '/support/:id',
    name: 'Support',
    component: () => import(/* webpackChunkName: "support" */ '../pages/support/_id.vue'),
    meta: {
      title: 'Support'
    }
  },
  // 404 ---------------------------------------------------------------------
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '../pages/404.vue'),
    meta: {
      title: 'Page inconnue'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

// Set meta
router.beforeEach((to, from, next) => {
  if (!to.meta) {
    to.meta = {}
  }
  document.title = to.meta.title
  next()
})

// Auth
router.beforeEach((to, from, next) => Auth(to, from, next))

export default router
