

































































import { Vue } from 'vue-property-decorator'
import { DEFAULT_CHECK_INTERVAL } from '@/constants/app'

interface Data {
  checkInterval: number;
  expanded: number;
  isLoading: boolean
}

export default Vue.extend({
  props: {
    title: {
      type: String,
      default: '',
      required: true,
    },
    icon: {
      required: true,
      default: '',
      type: String,
    },
    reload: {
      required: true,
      default: false,
      type: Boolean,
    },
    expand: {
      required: true,
      default: true,
      type: Boolean,
    },
    add: {
      required: false,
      type: Function,
      default: () => {
        //
      },
    },
    loading: {
      required: false,
      default: false,
      type: Boolean,
    },
    periodicCheck: {
      required: false,
      default: DEFAULT_CHECK_INTERVAL, // every minute
      type: [Number, Boolean],
    },
    load: {
      required: true,
      type: Function,
    },
    theme: {
      required: false,
      type: String,
      default: undefined,
    },
  },

  data(): Data {
    return {
      checkInterval: 0,
      expanded: 0,
      isLoading: false,
    }
  },

  async mounted(): Promise<void> {
    await this.refresh()

    let interval = 0
    interval = typeof this.periodicCheck === 'boolean' ? DEFAULT_CHECK_INTERVAL : this.periodicCheck
    this.checkInterval = window.setInterval(async () => {
      await this.refresh()
    }, interval)
  },

  beforeDestroy() {
    clearInterval(this.checkInterval)
  },

  methods: {
    async refresh(): Promise<void> {
      this.isLoading = true
      await this.load()
      this.isLoading = false
    },
  },
})
