

















































import { nanoid } from 'nanoid'
import Vue from 'vue'
import AppBar from '@/components/app-bar.vue'
import Utilities from '@/components/utilities.vue'
import { api } from '@/utils/api'
import { onRetry } from '@knitiv/api-client-javascript'

export default Vue.extend({
  name: 'Page',
  components: {
    AppBar,
    Utilities,
  },
  props: {
    classes: {
      type: String,
      default: '',
    },
    hideNavbar: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      mode: 0,
      url: '',
      supportRoomId: nanoid(),
    }
  },
  mounted() {
    this.mode = api().backendEngine
    this.url = api().backEndpoint

    api().on('retry', (error: onRetry, nextAttempt) => {
      console.log('error', error)
      console.log(`Attempt ${error.attemptNumber} failed. There are ${error.retriesLeft} retries left.`)

      // TODO retry now button
      this.$toast.warning(`Impossible de réaliser la requete. Essais restants: ${error.retriesLeft + 1} (in ${nextAttempt / 1000}s)`, {
        onClick: async (closeToast) => {
          closeToast()
        },
      })
    })
  },
  methods: {
    openSupport() {
      window.open(
        `/support/${this.supportRoomId}?initiator=true`,
        'support',
        'height=800,width=600',
      )
    },
  },
})
