













import { Vue, Component } from 'vue-property-decorator'
import BaseBlock from '@/components/home-blocs/base-block.vue'

@Component({
  components: {
    BaseBlock,
  },
})
export default class Workflows extends Vue {
  add(): void {
    console.log('hey')
  }
}
