import { ObjectInfoOutput } from '@knitiv/api-client-javascript'

import { Node } from './node'
import { Right } from './right'
import { Link } from './lint'
import { Attribute } from './attribute'
import { Representation } from './representation'

export interface IBaseNode {
  node: Node
  representations: Record<string, Representation>
  rights: Record<string, Right>
  links: Record<string, Link>
  attributes: Record<string, Attribute>
  fileId?: string;
  // kjson
  metadata?: string;
}

export class BaseNode implements IBaseNode {
  node: Node

  representations: Record<string, Representation>

  rights: Record<string, Right>

  links: Record<string, Link>

  attributes: Record<string, Attribute>

  fileId?: string;

  // kjson
  metadata?: string;

  constructor() {
    this.node = new Node()

    this.representations = {}
    this.rights = {}
    this.links = {}
    this.attributes = {}
  }

  static fromObjectInfo(input: ObjectInfoOutput) {
    console.log('fromObjectInfo')
    console.log('input', input)

    const node = new BaseNode()

    node.node.kid = input.objid
    node.node.name = input.objname
    node.node.type = input.objtype

    Object.entries(input.repre.list).forEach(([key, representation]) => {
      const newRepre = Representation.fromAPI(representation)
      node.addRepresentation(key, newRepre)
    })

    Object.entries(input.droit.list).forEach(([key, droit]) => {
      const newRight = Right.fromAPI(droit)
      node.addDroit(key, newRight)
    })

    return node
  }

  addRepresentation(key: string, representation: Representation) {
    this.representations[key] = representation
  }

  addDroit(key: string, right: Right) {
    this.rights[key] = right
  }

  setKid(kid: string) {
    this.node.setKid(kid)
  }

  // Assertions

  isClass() {
    return this.node.type === 'CLASS'
  }
}
