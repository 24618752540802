import _ from 'lodash'
import { Promisable } from 'type-fest'
import EventEmitter from 'eventemitter3'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
// import { useAccessor } from 'typed-vuex'
// import { store, accessorType } from '@/store'

dayjs.extend(utc)

export interface ExecuteResult<StateType, MetadataType> {
  state: StateType,
  metadata?: MetadataType
}

export interface UndoStackCreateAction<StateType, MetadataType> {
  execute(): Promisable<ExecuteResult<StateType, MetadataType>>
  rollback(metaData?: MetadataType): Promisable<StateType>
  onSave?(state: unknown): Promisable<unknown>
}

export type Mutation<StateType, PayloadType, MetadataType> = (state: StateType, payload: PayloadType) => UndoStackCreateAction<StateType, MetadataType>

export interface ITransaction<Payload = unknown, Metadata = unknown, Names = unknown, Types = unknown> {
  name: Names;
  author: string;
  date: string;
  type: Types;
  payload?: Payload;
  metadata?: Metadata;
}

export interface UndoStackExport<MutationNames, TypeNames> {
  stack: ITransaction<unknown, unknown, MutationNames, TypeNames>[];
}

export interface Events {
  commit: [ITransaction<unknown>];
  undo: [ITransaction<unknown>];
  redo: [ITransaction<unknown>];
}

export class Transaction<
  Payload = unknown,
  Metadata = unknown,
  MutationNames = unknown,
  TypeNames = unknown,
> implements ITransaction<Payload, Metadata, MutationNames, TypeNames> {
  name: MutationNames;

  type: TypeNames;

  payload?: Payload;

  metadata?: Metadata;

  author: string;

  date: string;

  constructor(transaction: ITransaction<Payload, Metadata, MutationNames, TypeNames>) {
    this.name = transaction.name
    this.author = transaction.author
    this.type = transaction.type
    this.payload = transaction.payload
    this.metadata = transaction.metadata
    this.date = transaction.date
  }

  export() {
    return this
  }
}

export type Mutations<StateType, PayloadType = unknown> = Record<string, Mutation<StateType, PayloadType, unknown>>
export interface Serializer {
  onSave: (input: any) => Promisable<unknown>,
  onRestore: (input: unknown) => Promisable<any>
}
export type Serializers = Record<string, Serializer>

export interface Store<StateType> {
  state: StateType;
  mutations: Mutations<StateType>;
  serializers: Serializers;
}

export type UnwrapStore<Type> = Type extends Store<infer X> ? X : never

export interface StackConfiguration {
  author: string;
}

export class UndoStack<StateType, MutationNames extends string, TypeNames extends string> extends EventEmitter<Events, ITransaction<unknown>> {
  private transactions: Transaction<unknown, unknown, MutationNames, TypeNames>[];

  private redoTransactions: Transaction<unknown, unknown, MutationNames, TypeNames>[];

  private store: Store<StateType>

  private configuration?: StackConfiguration;

  private details: Record<MutationNames, TypeNames>

  constructor(store: Store<StateType>, details: Record<MutationNames, TypeNames>, configuration?: StackConfiguration) {
    super()

    this.transactions = []
    this.redoTransactions = []
    this.configuration = configuration
    this.store = _.cloneDeep(store)
    this.details = details
  }

  setState(state: StateType) {
    this.store.state = state
  }

  getState() {
    return this.store.state
  }

  addMutation<PayloadType, MetadataType = unknown>(
    name: MutationNames,
    mutation: Mutation<StateType, PayloadType, MetadataType>,
    serializer?: Serializer,
  ): (payload: PayloadType) => Promise<void> {
    // @ts-ignore
    this.store.mutations[name] = mutation
    if (serializer) {
      this.store.serializers[name] = serializer
    }

    return (payload: PayloadType) => this.commit(name, payload)
  }

  createMutation<MetadataType = undefined>(mutation: UndoStackCreateAction<StateType, MetadataType>): UndoStackCreateAction<StateType, MetadataType> {
    return mutation
  }

  async commit(name: MutationNames, payload?: unknown) {
    const action = this.store.mutations[name]

    const clonedState = _.cloneDeep(this.store.state)
    const clonedPayload = _.cloneDeep(payload)

    if (action) {
      const { state: newState, metadata: meta } = await action(clonedState, clonedPayload).execute()
      const clonedMeta = _.cloneDeep(meta)
      const cloneNewState = _.cloneDeep(newState)

      // Do the action
      this.store.state = cloneNewState

      // const accessor: typeof accessorType = useAccessor(store)

      // Push the action to the stack
      const transaction = new Transaction({
        author: /* accessor.user?.profil.kid ??  */'<unknown>',
        name,
        payload: clonedPayload,
        date: dayjs.utc().format(),
        metadata: clonedMeta,
        type: this.details[name],
      })
      this.transactions.push(transaction)

      this.redoTransactions = []
      this.emit('commit', transaction)
    } else {
      console.error(`Action ${name} doesn't exist`)
    }
  }

  async undo() {
    const transaction = this.transactions.pop()

    if (transaction) {
      const clonedState = _.cloneDeep(this.store.state)

      let clonedPayload
      if (transaction.payload) {
        clonedPayload = _.cloneDeep(transaction.payload)
      }

      let clonedMeta
      if (transaction.metadata) {
        clonedMeta = _.cloneDeep(transaction.metadata)
      }

      const action = this.store.mutations[transaction.name]
      // Rollback current change
      const actionResult = await action(clonedState, clonedPayload).rollback(clonedMeta)
      const clonedNewState = _.cloneDeep(actionResult)
      this.store.state = clonedNewState

      this.redoTransactions.push(transaction)

      this.emit('undo', transaction)
    }
  }

  async redo() {
    const transaction = this.redoTransactions.pop()

    if (transaction) {
      const clonedState = _.cloneDeep(this.store.state)

      let clonedPayload
      if (transaction.payload) {
        clonedPayload = _.cloneDeep(transaction.payload)
      }

      const action = this.store.mutations[transaction.name]
      const { state: newState } = await action(clonedState, clonedPayload).execute()
      const clonedNewState = _.cloneDeep(newState)

      this.store.state = clonedNewState
      this.transactions.push(transaction)

      this.emit('redo', transaction)
    }
  }

  async import(exportData: UndoStackExport<MutationNames, TypeNames>) {
    for await (const transaction of exportData.stack) {
      let importedPayload = transaction.payload ?? undefined
      const serializers = this.store.serializers[transaction.name]
      if (serializers?.onRestore) {
        importedPayload = await serializers.onRestore(importedPayload)
      }

      await this.commit(transaction.name, importedPayload)
    }
  }

  async export(): Promise<UndoStackExport<MutationNames, TypeNames>> {
    const stack: ITransaction<unknown, unknown, MutationNames, TypeNames>[] = []

    for await (const transaction of this.transactions) {
      const newTransaction = _.cloneDeep(transaction)
      let exportedPayload
      if (newTransaction.payload) {
        exportedPayload = _.cloneDeep(newTransaction.payload)
        const serializers = this.store.serializers[newTransaction.name]
        if (serializers?.onSave) {
          exportedPayload = await serializers.onSave(exportedPayload)
        }
      }

      if (transaction.payload) {
        newTransaction.payload = exportedPayload
      }

      if (transaction.metadata) {
        newTransaction.metadata = transaction.metadata
      }

      stack.push(newTransaction)
    }

    const exportData: UndoStackExport<MutationNames, TypeNames> = {
      stack,
    }
    return exportData
  }

  canUndo() {
    return this.transactions.length > 0
  }

  canRedo() {
    return this.redoTransactions.length > 0
  }
}
