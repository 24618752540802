import { KnodeDefinition, SharedConstants } from '@knitiv/api-client-javascript'
import { getterTree, mutationTree, actionTree } from 'typed-vuex'
import Vue from 'vue'
import { api } from '@/utils/api'
import { accessor } from '.'

interface ImportFileState {
  representations: Record<string, Record<string, string>>;
  language: KnodeDefinition;
}

export const namespaced = true

const getDefaultState = (): ImportFileState => ({
  representations: {},
  language: 'K_NODE;UNIVERSEL',
})

export const state = getDefaultState

export type RootState = ReturnType<typeof state>

export const getters = getterTree(state, {
  get: (myState) => (kid: string, language?: KnodeDefinition/* , fallback?: string */) => {
    // TODO support fallback
    // TODO support fallback inside this class

    if (!myState.representations[kid]) {
      // TODO make a fetch
      return kid
    }

    // 1. Requested language
    // 2. Default language
    // 3. Universal
    // 4. First available
    if (language && myState.representations[kid][language]) {
      return myState.representations[kid][language]
    }

    if (myState.representations[kid][myState.language]) {
      return myState.representations[kid][myState.language]
    }

    if (myState.representations[kid][SharedConstants.representations.UNIVERSEL]) {
      return myState.representations[kid][SharedConstants.representations.UNIVERSEL]
    }

    return Object.values(myState.representations[kid])[0]
  },
})

export const mutations = mutationTree(state, {
  SET_LANG(state, language: KnodeDefinition) {
    state.language = language
  },

  ADD_REPRESENTATION_TO_LANGUAGE(
    state,
    { language, value, kid }: { kid: string, language: KnodeDefinition, value: string },
  ) {
    if (!state.representations[kid]) {
      Vue.set(state.representations, kid, {})
    }
    Vue.set(state.representations[kid], language, value)
  },
})

export const actions = actionTree({ state, getters, mutations }, {
  async fetchAll({ commit }, items: string[]) {
    const repres = await api().repreListNormal({
      items,
      mode: 'normal',
    })

    Object.values(repres.result).forEach((value) => {
      if (value !== '_RESTRICTED_AREA_') {
        commit('ADD_REPRESENTATION_TO_LANGUAGE', {
          kid: value.kid,
          language: value.lang.kid,
          value: value.name,
        })
      }
    })
  },

  async fetch(x, item: string) {
    await accessor.representations.fetchAll([item])
  },

  setLang({ commit }, language: KnodeDefinition) {
    commit('SET_LANG', language)
  },
})
