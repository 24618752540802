













































































































import Vue from 'vue'
import { UIGetContentMenuItem } from '@knitiv/api-client-javascript'
import { api } from '@/utils/api'

interface Data {
  logo: string | null;
  menu: UIGetContentMenuItem[];

  lang: 'fr';
  availableLanguages: {
    text: string;
    value: string;
  }[];

  iconAssociation: Record<string, string>;
}

export default Vue.extend({
  data(): Data {
    return {
      logo: null,
      menu: [],

      lang: 'fr',
      availableLanguages: [
        {
          text: 'Français',
          value: 'fr',
        },
        {
          text: 'Anglais',
          value: 'us',
        },
      ],

      iconAssociation: {
        'user-circle': 'mdi-account-circle',
        'share-alt': 'mdi-share-variant',
        exchange: 'mdi-swap-vertical',
        cogs: 'mdi-cogs',
        'exclamation-triangle': 'mdi-alert',
        'object-group': 'mdi-group',
        sitemap: 'mdi-sitemap',
        users: 'mdi-account-group',
        'bar-chart': 'mdi-chart-line',
        'question-circle': 'mdi-help-circle',
        comments: 'mdi-chat',
        file: 'mdi-file',
        'pie-chart': 'mdi-chart-pie',
      },
    }
  },

  computed: {
    databaseName() {
      return this.$accessor.user?.db?.name ?? '<Base de donnée inconnue>'
    },
  },

  async mounted(): Promise<void> {
    try {
      const res = await api().getLogo()
      this.logo = res.logo
    } catch (e) {
      console.error('e', e)
    }

    try {
      const res = await api().uiGetContent({
        path: 'MENU',
      })
      this.menu = res.result
    } catch (e) {
      if (e instanceof Error) {
        if (e.message !== 'Not implemented') {
          console.log('e', e)
        }
      } else {
        console.log('e', e)
      }

      if (api().backendEngine === 3) {
        this.menu.push({
          type: 'link',
          icon: 'table-edit',
          name: 'Import en masse',
          url: '/importfile',
        })
      }
    } finally {
      this.menu.push({
        type: 'link',
        icon: 'table-edit',
        name: 'Dev',
        url: '/dev',
      })
      this.menu.push({
        type: 'link',
        icon: 'table-edit',
        name: 'Éditeur de page',
        url: '/uibuilder',
      })
    }

    this.menu.unshift({
      type: 'link',
      disabled: true,
      icon: 'database',
      name: this.databaseName,
      url: '#',
    })
  },

  methods: {
    resolveIcon(icon: string): string {
      if (icon.startsWith('mdi')) {
        return icon
      }
      return this.iconAssociation[icon] || `mdi-${icon}`
    },

    async logout(): Promise<void> {
      await api().logout()
      this.$store.commit('SET_USER', {})
      await this.$router.push('/login')
    },
  },
})
