









































import { Vue, Component } from 'vue-property-decorator'
import { UIGetContentMenuItem } from '@knitiv/api-client-javascript'
import BaseBlock from '@/components/home-blocs/base-block.vue'
import { InjectAPI } from '@/utils/api'

@Component({
  components: {
    BaseBlock,
  },
  mixins: [
    InjectAPI,
  ],
})
export default class QuickActions extends Vue {
  actions: UIGetContentMenuItem[] = []

  async action(): Promise<void> {
    try {
      const response = await this.$api.uiGetContent({
        path: 'HOME/ACTIONS',
      })
      this.actions = response.result
    } catch (e) {
      if (e instanceof Error) {
        console.log('e.name', e.name)
        if (e.message !== 'Not implemented') {
          console.log('e', e)
        }
      } else {
        console.log('e', e)
      }
    }
  }
}
