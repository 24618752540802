import { KElementBase } from './kelement-base'

export class Node extends KElementBase {
  type: string;

  name: string;

  icon: string;

  constructor() {
    super()

    this.type = ''
    this.name = ''
    this.icon = 'alpha-n-box'
  }

  setKid(kid: string) {
    this.kid = kid
  }

  setName(name: string) {
    this.name = name
  }

  setIcon(icon: string) {
    this.icon = icon
  }
}
