




































import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class Prompt extends Vue {
  get confirm() {
    return this.$accessor.modal.confirm
  }

  resolve(value: string) {
    this.$accessor.modal.SET_CONFIRM_STATUS(false)
    this.confirm.resolve(value)
  }

  get maxWidth() {
    return this.$vuetify.breakpoint.xsOnly ? '100%' : 600
  }
}
