import {
  APIConstructorV4,
  APIConstructorV3,
  APIConstructorV5,
  APIConstructor,
  AuthenticationObject,
  WsURL,
} from '@knitiv/api-client-javascript'
import { PartialDeep } from 'type-fest'
import { NavigationGuard, NavigationGuardNext, Route } from 'vue-router'
import { APISIngleton, _wsSingleton } from '@/utils/api'
import { accessor, accessorType } from '@/store'

export type EngineVersion = 3 | 4 | 5
export type EngineVersionString = '3' | '4' | '5'

const ALLOW_WITHOUT_TOKEN = ['Login', 'LoginWorkspace', 'LoginExtra']

const engine = process.env.VUE_APP_API_ENGINE ?? '4'
const endpoint = process.env.VUE_APP_API_URL ?? '/'

interface BridgeParams {
  token: string;
  // from: EngineVersionString; // Version it's coming from
  // endpoint: string;
}

interface Session {
  mode: EngineVersion,
  hideNavbar: boolean,
  allowedRoutes: string[],
  user: AuthenticationObject
}

export const multiVersionApi = (engine: EngineVersion): APIConstructor => {
  const match = {
    3: () => new APIConstructorV3(),
    4: () => new APIConstructorV4(),
    5: () => new APIConstructorV5(),
  }
  // @ts-ignore
  return match[engine]()
}

const hasBridge = (query: any) => {
  const params: BridgeParams = query as BridgeParams

  return /* params.endpoint && params.from && */ params.token
}

const _hasStorage = (storage: 'localStorage' | 'sessionStorage', key: string, handleValue: any) => {
  const _session = window[storage].getItem(key)
  if (!_session) {
    return false
  }

  return handleValue(_session)
}

// No check since it MUST BE defined (checked by hasXXXStorage)
const _getStore = <T>(storage: 'localStorage' | 'sessionStorage', key: string, handleValue: (session: string) => T): T => {
  const _store = window[storage].getItem(key) ?? '{}'
  const session = handleValue(_store)
  return session
}

const hasLocalStorage = (key: string, handleValue: any) => _hasStorage('localStorage', key, handleValue)

const hasSessionStorage = (key: string, handleValue: any) => _hasStorage('sessionStorage', key, handleValue)

const hasStore = (store: typeof accessorType) => store.user

const setupContext = (token: string/* , from: EngineVersionString, endpoint: string */) => {
  const mode = Number.parseInt(engine, 10) as EngineVersion
  const context: PartialDeep<Session> = {
    mode,
    hideNavbar: mode !== 5,
    allowedRoutes: mode === 5
      ? []
      : [
        '/importfile/',
      ],
    user: {
      url: {
        back: endpoint,
        file: endpoint,
      },
      token: {
        id: token,
      },
    },
  }
  return context
}

const loadSession = (session: PartialDeep<Session>) => {
  let api
  if (session.mode) {
    api = multiVersionApi(session.mode)
  }

  if (api && session.user?.url?.back) {
    api.setBackEndpoint(session.user.url.back) // Set endpoint for tokenCheck
  }

  if (api && session.user?.token?.id) {
    api.setToken(session.user.token.id)
  }

  return api
}

const redirectToLogin = (route: Route, redirect: NavigationGuardNext<Vue>) => {
  const redirectQuery = `?redirect=${route.fullPath}`
  return route.name === 'index' ? redirect('/login') : redirect(`/login${redirectQuery}`)
}

const prepareAPI = async (
  api: APIConstructor | undefined,
  context: PartialDeep<Session>,
  accessor: typeof accessorType,
  route: Route,
  redirect: NavigationGuardNext<Vue>,
  skipTokenCheck = false,
) => {
  if (api !== undefined) {
    api.setBackEndpoint(endpoint)

    try {
      if (!skipTokenCheck && context.user?.token?.id) {
        // Check token validity
        const response = await api.tokenCheck({
          retry: 0
        })

        // if (api.backendEngine === 3) {
        // if (context.user.db && response.db) {
        // context.user.db.name = response.db.name
        // context.user.db.kid = response.db.kid
        // }
        // context.user.config = response.config
        // } else if (api.backendEngine === 4 || api.backendEngine === 5) {
        context.user = response
        // }

        if (context.user.config?.KWEBSOCKET?.url) {
          const url = process.env.NODE_ENV === 'development' ? ('ws://localhost:2000' as WsURL) : context.user.config.KWEBSOCKET.url
          // non blocking init
          _wsSingleton.setURL(url)
          if (context.user && context.user.token && context.user.token.id) {
            _wsSingleton.setToken(context.user.token.id)
          } else {
            throw new Error('Invalid user object')
          }
        }

        // Login
        accessor.SET_USER(context.user)
        if (!context.mode) {
          throw new Error('Invalid mode')
        }
        accessor.SET_MODE(context.mode)

        // Update configuration
        api.setToken(response.token.id)
        api.setBackEndpoint(response.url.back)
        api.setFileEndpoint(response.url.file)
      }

      APISIngleton.setInstance(api)
      return redirect()
    } catch (e) {
      console.error('Error while setting up authentication', e)
      return redirectToLogin(route, redirect)
    }
  } else {
    console.error('No API')
    return redirectToLogin(route, redirect)
  }
}

export const Auth: NavigationGuard<Vue> = (route, from, redirect) => {
  if (route.name && ALLOW_WITHOUT_TOKEN.includes(route.name)) {
    const context = setupContext('')
    const api = loadSession(context)
    return prepareAPI(api, context, accessor, route, redirect)
  }

  if (hasBridge(route.query)) {
    const { token } = route.query as unknown as BridgeParams

    const context = setupContext(token)
    sessionStorage.setItem('session', JSON.stringify(context))
    const api = loadSession(context)
    return prepareAPI(api, context, accessor, route, redirect)
  }

  if (hasSessionStorage('session', (_session: string) => {
    const session = JSON.parse(_session) as Session
    if (session && session.user) {
      return true
    }

    return false
  })) {
    const context = _getStore<Session>('sessionStorage', 'session', (value: string) => JSON.parse(value))

    const api = loadSession(context)
    return prepareAPI(api, context, accessor, route, redirect)
  }

  if (hasStore(accessor) && accessor.user) {
    // store.
    const context: Session = {
      allowedRoutes: [],
      hideNavbar: false,
      mode: accessor.mode,
      user: accessor.user,
    }

    const api = loadSession(context)
    return prepareAPI(api, context, accessor, route, redirect)
  }

  if (hasLocalStorage('token', (token: string) => token)) {
    const token = _getStore<string>('localStorage', 'token', (value) => value)
    const context = setupContext(token)

    const api = loadSession(context)
    return prepareAPI(api, context, accessor, route, redirect)
  }

  // Got nothing ?
  // Then, not logged
  const redirectQuery = `?redirect=${route.fullPath}`
  return route.name === 'index' ? redirect('/login') : redirect(`/login${redirectQuery}`)
}

export default Auth
