import { getterTree, mutationTree, actionTree } from 'typed-vuex'

export interface ModalState {
  custom: any;
  alert: any;
  prompt: any;
  confirm: any;
}

export const namespaced = true

// TODO add types
export const state = (): ModalState => ({
  custom: {
    component: null,
    show: false,
    resolve: null,
    reject: null,
    props: {},
  },
  alert: {
    show: false,
    resolve: null,
    reject: null,
    props: {},
  },
  prompt: {
    show: false,
    resolve: null,
    reject: null,
    props: {},
  },
  confirm: {
    show: false,
    resolve: null,
    reject: null,
    props: {},
  },
})

export type RootState = ReturnType<typeof state>

export const getters = getterTree(state, {})

export const mutations = mutationTree(state, {
  // custom modal
  SET_MODAL_CONTENT(state, component) {
    state.custom.component = component
  },
  SET_MODAL_PROPS(state, props) {
    state.custom.props = props
  },
  SET_MODAL_RESOLVE(state, resolve) {
    state.custom.resolve = resolve
  },
  SET_MODAL_REJECT(state, reject) {
    state.custom.reject = reject
  },
  SET_MODAL_STATUS(state, show) {
    state.custom.show = show
  },

  // alert
  SET_ALERT_PROPS(state, props) {
    state.alert.props = props
  },
  SET_ALERT_RESOLVE(state, resolve) {
    state.alert.resolve = resolve
  },
  SET_ALERT_REJECT(state, reject) {
    state.alert.reject = reject
  },
  SET_ALERT_STATUS(state, show) {
    state.alert.show = show
  },

  // prompt
  SET_PROMPT_PROPS(state, props) {
    state.prompt.props = props
  },
  SET_PROMPT_RESOLVE(state, resolve) {
    state.prompt.resolve = resolve
  },
  SET_PROMPT_REJECT(state, reject) {
    state.prompt.reject = reject
  },
  SET_PROMPT_STATUS(state, show) {
    state.prompt.show = show
  },

  SET_CONFIRM_PROPS(state, props) {
    state.confirm.props = props
  },
  SET_CONFIRM_RESOLVE(state, resolve) {
    state.confirm.resolve = resolve
  },
  SET_CONFIRM_REJECT(state, reject) {
    state.confirm.reject = reject
  },
  SET_CONFIRM_STATUS(state, show: boolean) {
    state.confirm.show = show
  },
})

export const actions = actionTree({ state, getters, mutations }, {})
