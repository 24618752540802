import pathify from 'vuex-pathify'
import Vue from 'vue'
import Vuex from 'vuex'
import {
  getAccessorType, getterTree, mutationTree, actionTree, useAccessor
} from 'typed-vuex'

import { AuthenticationObject } from '@knitiv/api-client-javascript'
import * as pagebuilder from '@/store/pagebuilder'
import * as importfile from '@/store/importfile'
import * as classdesigner from '@/store/classdesigner'
import * as modal from '@/store/modal'
import * as representations from '@/store/representations'
import { EngineVersion } from '@/middleware/auth'

export const plugins = [pathify.plugin]

Vue.use(Vuex)

type State = {
  page: string;
  user: AuthenticationObject | null;
  mode: EngineVersion;
  hideNavBar: boolean;
  allowNavigatingTo: string[]
}

export const state = (): State => ({
  page: '/',
  user: null,

  // Change application mode for custom pages like /importfile
  mode: 5,

  hideNavBar: true,
  allowNavigatingTo: [],
})

export type RootState = ReturnType<typeof state>

export const getters = getterTree(state, {
  currentPage(state) {
    return state.page
  },
})

export const mutations = mutationTree(state, {
  SET_PAGE(state, page) {
    state.page = page
  },

  SET_USER(state, user) {
    state.user = user
  },

  SET_MODE(state, mode: EngineVersion) {
    state.mode = mode
  },

  SET_HIDE_NAVBAR(state, hide: boolean) {
    state.hideNavBar = hide
  },

  SET_ALLOW_NAVIGATING_TO(state, pages: string[]) {
    Vue.set(state, 'allowNavigatingTo', pages)
  },
})

export const actions = actionTree({ state, getters, mutations }, {})

export const storePattern = {
  state,
  getters,
  mutations,
  actions,
  modules: {
    pagebuilder,
    importfile,
    modal,
    classdesigner,
    representations,
  },
}

const store = new Vuex.Store(storePattern)

export const accessorType = getAccessorType(storePattern)

export const accessor = useAccessor(store, storePattern)

Vue.prototype.$accessor = accessor

export default store
