<template>
  <div>
    <!-- Control a custom modal -->
    <BaseModal />

    <!-- Control an alert modal -->
    <Alert />

    <!-- Control an alert modal -->
    <Prompt />

    <!-- Control a confirm modal -->
    <Confirm />
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import BaseModal from '@/components/modals/modal.vue'
import Alert from '@/components/modals/alert.vue'
import Prompt from '@/components/modals/prompt.vue'
import Confirm from '@/components/modals/confirm.vue'

@Component({
  components: {
    BaseModal,
    Alert,
    Prompt,
    Confirm,
  },
})
export default class Utilities extends Vue {}
</script>

<style scoped>

</style>
