



















import { Component, Vue } from 'vue-property-decorator'

@Component
export default class BaseModal extends Vue {
  get modal() {
    return this.$accessor.modal.custom
  }

  resolveModal(arg: any) {
    this.$accessor.modal.SET_MODAL_STATUS(false)
    this.modal.resolve(arg)
  }

  rejectModal(arg: any) {
    this.$accessor.modal.SET_MODAL_STATUS(false)
    this.modal.reject(arg)
  }

  get maxWidth() {
    return (this.$vuetify.breakpoint.xsOnly ? '100%' : (this.modal.props.maxWidth))
  }

  get scrollable() {
    return this.modal.props.scrollable ?? false
  }
}
