



















import Vue from 'vue'

import QuickActions from '@/components/home-blocs/quick-actions.vue'
import Bookmarks from '@/components/home-blocs/bookmarks.vue'
import Workspaces from '@/components/home-blocs/workspaces.vue'
import Queries from '@/components/home-blocs/queries.vue'
import News from '@/components/home-blocs/news.vue'
import Workflows from '@/components/home-blocs/workflows.vue'
import Page from '@/components/layouts/page.vue'

export default Vue.extend({
  components: {
    QuickActions,
    Bookmarks,
    Workspaces,
    Queries,
    News,
    Workflows,
    Page,
  },
  data() {
    return {
      name: '',
      blocks: [
        QuickActions,
        Bookmarks,
        Workspaces,
        Queries,
        News,
        Workflows,
      ]
    }
  },

  async mounted() {
    //
  }
})
